import * as React from "react";
import { EGDSSubheading } from "@egds/react-core/text";
import { EGDSLink } from "@egds/react-core/link";
import { Travelers } from "src/components/shared/Travelers/Travelers";
import { Dates } from "src/components/shared/Dates/Dates";
import { observer } from "mobx-react";
import { Duration } from "components/shared/Duration/Duration";
import { Locations3PP } from "src/components/shared/Locations3PP/Locations3PP";
import { SubmitRow } from "src/components/shared/StorefrontWizard/SubmitRow/SubmitRow";
import { OffersWithTransferToggle } from "./components/OffersWithTransferToggle";
import {
  EGDSLayoutGrid,
  EGDSLayoutConditionalGridSpan,
  EGDSLayoutConditionalGridTrack,
} from "@egds/react-core/layout-grid";
import { LobErrorSummary } from "src/components/shared/LobErrorSummary/LobErrorSummary";
import { withLocalization } from "@shared-ui/localization-context";
import { TravelersTriggerTypes } from "src/components/shared/TravelersField/typings";
import { Wizard3ppPWAProps, Wizard3ppPWAFlexModuleResult } from "./typings";
import { LobTitle } from "components/shared/LobTitle/LobTitle";
import { getFmId } from "src/stores/ExperienceTemplateStore";
import { EGDSSpacing } from "@egds/react-core/spacing";

@withLocalization
@observer
export class Wizard3ppPWAView extends React.Component<Wizard3ppPWAProps> {
  constructor(props: Wizard3ppPWAProps) {
    super(props);
  }

  componentDidMount() {
    const {
      wizardState: { thirdPartyPackageWizardState },
      context,
    } = this.props;

    if (context) {
      thirdPartyPackageWizardState.loadOrigins(context.site.id).then(() => {
        thirdPartyPackageWizardState.updateDateFromConfig();
      });
    }
  }

  public render() {
    const {
      wizardState: { thirdPartyPackageWizardState },
      context,
      flexModuleModelStore,
      templateComponent,
      getLocError,
      setInputsRefs,
      l10n: { formatText },
    } = this.props;
    const { config: thirdConfig } = thirdPartyPackageWizardState;
    const { showGroupLink } = thirdConfig;
    const {
      metadata: { id },
      config: { fmTitleId },
    } = templateComponent;
    const model = flexModuleModelStore.getModel(id) as Wizard3ppPWAFlexModuleResult | null;
    if (!model) {
      return null;
    }

    const mainGridColumns: EGDSLayoutConditionalGridTrack = { small: 2, medium: 12 };
    const errorSummaryColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 12 };
    const locationsColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 6 };
    const datesColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 3 };
    const durationColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 3 };
    const travelersColSpan: EGDSLayoutConditionalGridSpan = { small: 2, medium: 12 };
    const singleDate = !thirdConfig.date.queryParam.end;

    const { durationInvalidKey, numberOfErrors, moreThanOneError, errorInputRef } = thirdPartyPackageWizardState;

    const changeDuration = (selectEvent: React.ChangeEvent<HTMLSelectElement>) => {
      thirdPartyPackageWizardState.setDuration(selectEvent.target.value);
    };

    const isApiCallEnabled =
      context && thirdPartyPackageWizardState.config.siteIdsWithApiCall.includes(context.site.id);

    const globalConfig = this.props.wizardState.globalWizardState.config;
    const fmId = getFmId(templateComponent);

    return (
      <form
        noValidate
        action={thirdPartyPackageWizardState.formAction}
        autoComplete="off"
        className="WizardThirdPartyPackagePWA"
        data-testid="wizard"
        onSubmit={thirdPartyPackageWizardState.submit}
        id={thirdConfig.elementId}
        data-fm={fmId}
        data-fm-title-id={fmTitleId}
      >
        {model.titleFromPageHeader && <LobTitle lobState={thirdPartyPackageWizardState} />}
        {showGroupLink && (
          <EGDSSpacing padding={{ blockend: "four" }} margin={{ blockstart: "one" }}>
            <div>
              <EGDSSubheading>
                <EGDSLink inline>
                  <a href="http://allinclusiveforgroups.expedia.ca/en-CA/LandGroup">
                    {formatText(thirdConfig.groupLinkKey, 10)}
                  </a>
                </EGDSLink>
              </EGDSSubheading>
            </div>
          </EGDSSpacing>
        )}
        <EGDSLayoutGrid columns={mainGridColumns} space="three">
          {numberOfErrors > 0 && (
            <LobErrorSummary
              locHeadingToken={getLocError!(false)!}
              locHeadingArgs={moreThanOneError() && [numberOfErrors]}
              locLinkToken={getLocError!(true)}
              linkClickFocusId="id-inclusive-error"
              inputErrorRef={errorInputRef}
              colSpan={errorSummaryColSpan}
              setInputsRefs={setInputsRefs}
            />
          )}
          <Travelers
            lobState={thirdPartyPackageWizardState}
            config={thirdConfig.travelers}
            colSpan={travelersColSpan}
            triggerType={TravelersTriggerTypes.LINK_BUTTON}
            isApiCallEnabled={isApiCallEnabled}
          />
          <Locations3PP lobState={thirdPartyPackageWizardState} colSpan={locationsColSpan} />
          <Dates
            singleDate={singleDate}
            lobState={thirdPartyPackageWizardState}
            colSpan={datesColSpan}
            setInputsRefs={setInputsRefs}
          />
          <Duration
            colSpan={durationColSpan}
            durations={thirdPartyPackageWizardState.durations}
            changeDuration={changeDuration}
            invalidDurationKey={durationInvalidKey}
            inputValue={thirdPartyPackageWizardState.duration}
            inputName={thirdConfig.duration.queryParam}
            placeholderKey={thirdConfig.duration.placeholderText}
          />
        </EGDSLayoutGrid>
        {thirdConfig.offersWithTransfer.isVisible && (
          <OffersWithTransferToggle lobState={thirdPartyPackageWizardState} />
        )}
        <SubmitRow lobState={thirdPartyPackageWizardState} rfrr="SearchClick_ThreePP" globalConfig={globalConfig} />
      </form>
    );
  }
}
